import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { ChakraProvider } from "@chakra-ui/provider"
import theme from './theme'

import App from "./App"

const rootElement = document.getElementById("root")
ReactDOM.render(
    <StrictMode>
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </StrictMode>,
    rootElement
)
