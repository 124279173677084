import React from 'react'
import {
    Flex,
    Heading,
    Spinner,
} from '@chakra-ui/react'

const LoadingSpinner = ({ title = "" }) => (
    <Flex w="full" h="full" direction="column" gridRowGap={8} alignItems="center" justifyContent="center" py={8}>
        <Spinner width="80px" height="80px"
                 thickness="6px"
                 speed="0.65s"
                 emptyColor="gray.200"
                 color="blue.500"/>
        { title &&
        <Heading size="md">{title}</Heading>
        }
    </Flex>
)

export default LoadingSpinner