import React from 'react'
import _ from 'lodash'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    ButtonGroup,
} from '@chakra-ui/react'

const BaseModal = ({ isOpen, trapFocus, title, size = "md", onClose, onCloseText = "Close", buttons, children }) => (
    <Modal isOpen={isOpen} trapFocus={trapFocus} isCentered size={size} closeOnOverlayClick={!!onClose} onClose={onClose}>
        <ModalOverlay />
        <ModalContent textAlign="center" py={4}>
            { title &&
            <ModalHeader fontWeight={600} fontSize="1.75rem" mt={2}>{title}</ModalHeader>
            }
            <ModalBody>
                <Flex direction="column" gridRowGap={4} alignItems="center">
                    { children }
                </Flex>
            </ModalBody>
            <ModalFooter bg="white" textAlign="center" borderBottomRadius="md" justifyContent={buttons ? "space-between" : "space-around"}>
                { onClose && onCloseText &&
                <Button colorScheme="blue" onClick={onClose}>{onCloseText}</Button>
                }
                { buttons &&
                <ButtonGroup>
                    { _.map(buttons, ({ text, colorScheme, onClick, variant }) => (
                        <Button colorScheme={colorScheme} variant={variant} onClick={onClick}>{text}</Button>
                    ))}
                </ButtonGroup>
                }
            </ModalFooter>
        </ModalContent>
    </Modal>
)

export default BaseModal