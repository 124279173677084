import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/variable.css"

import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'

import LoadingSpinner from './components/LoadingSpinner'
import GuessClue      from './components/GuessClue'

import sleeper from './helpers/sleeper'

import config from './config'
const {
    apiUrl,
} = config
const FAKE_WAIT_SECONDS = 1

const helloMessages = [
    'Hello', 'Salut', 'Privet', 'Nǐ hǎo', 'Ciao', 'Salve', 'Konnichiwa', 'Yā, Yō', 'Guten Tag', 'Hallo', 'Olá', 'Oi',
    'Anyoung haseyo', 'Anyoung', 'Hej', 'Goddag', 'Halløj', 'Shikamoo', 'Hoi', 'Goedendag', 'Yassas', 'Dzień dobry',
    'Selamat siang', 'Namaste', 'Merhaba', 'Shalom', 'Tjena',
]

const App = () => {
    const [response, setResponse] = useState(null)
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        if (!response && !isFetching) {
            setIsFetching(true)
            axios.get(`${apiUrl}/guessData`)
            .then(sleeper(FAKE_WAIT_SECONDS * 1000))
            .then(response => setResponse(response.data))
            .then(() => setIsFetching(false))
        }

    }, [response, isFetching])

    if (!response) {
        return <LoadingSpinner title={`${_.sample(helloMessages)}, Monica.`}/>
    }

    const { nextClue, errors } = response
    return <GuessClue nextClue={nextClue} errorMessages={errors} />
}

export default App