import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import {
    Heading,
    Center,
    Flex,
    PinInput,
    PinInputField,
    Button,
    useDisclosure,
    Link,
} from '@chakra-ui/react'

import BaseModal from './BaseModal'

const GuessClue = ({ nextClue, errorMessages }) => {
    const { phrase: answer, url } = nextClue
    const strippedAnswer = useMemo(() => answer.replace(/-/g, ''), [answer])

    const [phrase, setPhrase] = useState("")
    const breaks = useMemo(() => {
        let i = -1
        const parts = answer.split("-")
        return new Set(_.map(parts, part => {
            i += part.length
            return i
        }))
    }, [answer])

    const { isOpen: errorIsOpen,   onToggle: toggleError   } = useDisclosure()
    const { isOpen: successIsOpen, onToggle: toggleSuccess } = useDisclosure()
    const [errMessage, setErrMessage] = useState("")

    const onSubmit = React.useCallback(() => {
        if (phrase === answer.replace(/-/g, '')) {
            toggleSuccess()
        } else {
            setErrMessage(_.sample(errorMessages))
            toggleError()
        }
    }, [phrase, answer, errorMessages, toggleError, toggleSuccess])
    const onChange = React.useCallback(value => setPhrase(value), [])

    const blankFields = (
        <Flex gridColumnGap={2}>
            <PinInput size="lg" type="alphanumeric" onChange={onChange} autoFocus manageFocus={true} defaultValue={phrase}>
            { _.times(strippedAnswer.length, index => (
                    <PinInputField key={`field.${index}`} bg="white" textTransform="uppercase" fontWeight="bold" mr={breaks.has(index) ? 6 : 0}/>
            ))}
            </PinInput>
        </Flex>
    )

    return (
        <>
        <Center h="full" w="full" bg="#DEDEDE">
            <Flex direction="column" mt={4} gridRowGap={8} alignItems="center">
                <Heading>Treasure Hunt</Heading>
                <Flex direction="column" alignItems="center" gridRowGap={10}>
                    { blankFields }
                    <Button colorScheme="blue" disabled={phrase.length !== strippedAnswer.length}
                            onClick={onSubmit}>Submit</Button>
                </Flex>
            </Flex>
        </Center>
        <BaseModal isOpen={errorIsOpen}   onClose={toggleError}   title={errMessage} />
        <BaseModal isOpen={successIsOpen} onClose={toggleSuccess} title="Woohoo!" onCloseText="" trapFocus={false}>
            <Link color="blue.500" pt={3} fontSize={20} fontWeight="bold" _hover={{ textTransform: 'none' }} href={url} isExternal _focus={{ border: 'none' }}>
                Next Clue
            </Link>
        </BaseModal>
        </>
    )
}

export default GuessClue